// Technolgies Carousel Functionality

window.technologiesCarousel = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.createCarousel();
        }
    }

    app.cache = () => {
        app.$c = {
            carouselSection: document.getElementsByClassName( 'sidebyside-content--posts' ),
            // controlCenterScreen: [...document.getElementsByClassName( 'control-center__screen' )],
        }
    }

    app.hasSection = () => {

        return app.$c.carouselSection.length;
    }

    app.createCarousel = () => {

        $( app.$c.carouselSection ).on( 'init', ( slick ) => {
            
            $( '.sidebyside-content--posts .entry .post-entry-link' ).matchHeight({
                byRow: true
            });
        });
				
        $( app.$c.carouselSection ).slick({
            slidesToShow: 3,
            arrows: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });
				
				
    }

    app.init();

} ( window, jQuery, window.technologiesCarousel ) );