// Side Icon Scroll Functionality

window.iconScroll = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasHomepageSection() ) {

            app.bindHomepageEvents();
        } else if( app.hasSection() ) {

            app.bindEvents();
        }
    }

    app.cache = () => {
        app.$c = {
            scrollIcon: document.getElementsByClassName( 'content__icon' ),
            homepageScrollIcon: document.getElementsByClassName( 'homepage-bottom__icon' ),
            iconContainer: document.querySelector( '.content' ),
            homepageIconContainer: document.querySelector( '.homepage-bottom__inner' ),
        }
    }

    app.hasSection = () => {

        return app.$c.scrollIcon.length;
    }

    app.hasHomepageSection = () => {

        return app.$c.homepageScrollIcon.length;
    }

    app.bindEvents = () => {

        window.addEventListener( 'load', app.doIconScroll );
        window.addEventListener( 'scroll', app.doIconScroll );
    }

    app.bindHomepageEvents = () => {

        window.addEventListener( 'load', app.doHomepageIconScroll );
        window.addEventListener( 'scroll', app.doHomepageIconScroll );
    }

    app.doIconScroll = () => {
        let iconHeight = Math.round( app.$c.scrollIcon[0].clientWidth );
        let pageSectionHeight = Math.round( app.$c.iconContainer.clientHeight );
        let currentPageSectionPositionTop = Math.round( app.$c.iconContainer.getBoundingClientRect().top );
        let currentPageSectionPositionBottom = Math.round( app.$c.iconContainer.getBoundingClientRect().bottom );
        
        if( currentPageSectionPositionTop < 40 ) {
            
            if( ( currentPageSectionPositionBottom - iconHeight ) > 0  ) {

                app.$c.scrollIcon[0].style.transform = 'translateY(' + ( -currentPageSectionPositionTop + 40 ) + 'px) rotate(90deg)';
            } else {

                app.$c.scrollIcon[0].style.transform = 'translateY(' + ( pageSectionHeight - iconHeight ) + 'px) rotate(90deg)';
            }
        } else {

            app.$c.scrollIcon[0].style.transform = 'translateY(0) rotate(90deg)';
        }
    }

    app.doHomepageIconScroll = () => {
        let iconHeight = Math.round( app.$c.homepageScrollIcon[0].clientWidth );
        let pageSectionHeight = Math.round( app.$c.homepageIconContainer.clientHeight );
        let currentPageSectionPositionTop = Math.round( app.$c.homepageIconContainer.getBoundingClientRect().top );
        let currentPageSectionPositionBottom = Math.round( app.$c.homepageIconContainer.getBoundingClientRect().bottom );
        
        if( currentPageSectionPositionTop < 40 ) {
            
            if( ( currentPageSectionPositionBottom - iconHeight ) > 0  ) {

                app.$c.homepageScrollIcon[0].style.transform = 'translateY(' + ( -currentPageSectionPositionTop + 40 ) + 'px) rotate(90deg)';
            } else {

                app.$c.homepageScrollIcon[0].style.transform = 'translateY(' + ( pageSectionHeight - iconHeight ) + 'px) rotate(90deg)';
            }
        } else {

            app.$c.homepageScrollIcon[0].style.transform = 'translateY(0) rotate(90deg)';
        }
    }

    app.init();

} ( window, jQuery, window.iconScroll ) );