// FAQ Functionality

window.faq = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.bindEvents();
        }
    }

    app.cache = () => {
        app.$c = {
            faqSection: document.getElementsByClassName( 'page-content__faqs' ),
            faqButton: document.querySelectorAll( '.page-content__faq-question button' ),
        }
    }

    app.hasSection = () => {

        return app.$c.faqSection.length;
    }

    app.bindEvents = () => {

        $( app.$c.faqButton ).on( 'click', app.displayFaq )
    }

    app.displayFaq = e => {

        let clickedFaq = e.currentTarget;
        let isFaqExpanded = clickedFaq.getAttribute( 'aria-expanded' );
        let faqControls = clickedFaq.getAttribute( 'aria-controls' );
        let faqAccordion = document.getElementById( faqControls );
        let faqContentInner = faqAccordion.querySelector( '.page-content__faq-inner' );
        let faqContentHeight = faqContentInner.offsetHeight;

        if( isFaqExpanded === 'true' ) {
            faqAccordion.style.height = '0px';
            clickedFaq.setAttribute( 'aria-expanded', false );
        } else {
            faqAccordion.style.height = faqContentHeight + 'px';
            clickedFaq.setAttribute( 'aria-expanded', true );
        }
    }

    app.init();

} ( window, jQuery, window.faq ) );