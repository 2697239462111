// Industry Carousel Functionality

window.industryCarousel = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.createCarousel();
        }
    }

    app.cache = () => {
        app.$c = {
            carouselSection: document.getElementsByClassName( 'homepage-industry__industries' ),
            // controlCenterScreen: [...document.getElementsByClassName( 'control-center__screen' )],
        }
    }

    app.hasSection = () => {

        return app.$c.carouselSection.length;
    }

    app.createCarousel = () => {

        $( app.$c.carouselSection ).on( 'init', ( slick ) => {
            
            $( '.homepage-industry__industry .homepage-industry__industry--name' ).matchHeight({
                byRow: false
            });
        });

        $( app.$c.carouselSection ).slick({
            // centerMode: true,
            slidesToShow: 3,
            arrows: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });
    }

    app.init();

} ( window, jQuery, window.industryCarousel ) );