// Technolgies Slider Functionality

window.technologiesSlider = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.createSlider();
        }
    }

    app.cache = () => {
        app.$c = {
            sliderSection: document.getElementsByClassName( 'sidebyside-content--steps-slider' ),
            // controlCenterScreen: [...document.getElementsByClassName( 'control-center__screen' )],
        }
    }

    app.hasSection = () => {

        return app.$c.sliderSection.length;
    }

    app.createSlider = () => {

        /*$( app.$c.sliderSection ).on( 'init', ( slick ) => {
            
            $( '.homepage-industry__industry .homepage-industry__industry--name' ).matchHeight({
                byRow: false
            });
        });*/
				
				var currentSlide;
				var slidesCount;
				var sliderCounter = document.createElement('div');
				sliderCounter.classList.add('slick-counter');
				

				var updateSliderCounter = function(slick, currentIndex) {
					currentSlide = slick.slickCurrentSlide() + 1;
					slidesCount = slick.slideCount;
					$(sliderCounter).html('<strong>The Steps</strong> <span class="line"></span> <span><strong class="counter">' + currentSlide + '</strong>' + ' of <span class="total">' + slidesCount + '</span></span>')
				};
				
				$( app.$c.sliderSection ).on('init', function(event, slick) {
					$( app.$c.sliderSection ).append(sliderCounter);
					updateSliderCounter(slick);
				});

				$( app.$c.sliderSection ).on('afterChange', function(event, slick, currentSlide) {
					updateSliderCounter(slick, currentSlide);
				});

        $( app.$c.sliderSection ).slick({
            // centerMode: true,
            dots: true,
						vertical: true,
						infinite: true,
						autoplay: true,
						autoplaySpeed: 3000
        });
				
				
    }

    app.init();

} ( window, jQuery, window.technologiesSlider ) );