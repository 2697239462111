// Industry Carousel Functionality

window.industryGallery = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.createCarousel();
        }
    }

    app.cache = () => {
        app.$c = {
            gallerySection: document.getElementsByClassName( 'industry__section--gallery' ),
            mainGallery: document.getElementsByClassName( 'industry-gallery__images' ),
            navigationGallery: document.getElementsByClassName( 'industry-gallery__navigation' ),
        }
    }

    app.hasSection = () => {

        return app.$c.gallerySection.length;
    }

    app.createCarousel = () => {

        $( app.$c.mainGallery ).slick({
            asNavFor: app.$c.navigationGallery,
            slidesToScroll: 1,
            slidesToShow: 1,
            arrows: false,
            dots: false,
            fade: true,
            rows: 0,
        });

        $( app.$c.navigationGallery ).slick({
            asNavFor: app.$c.mainGallery,
            slidesToScroll: 1,
            slidesToShow: 4,
            arrows: true,
            dots: false,
            rows: 0,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        });
    }

    app.init();

} ( window, jQuery, window.industryGallery ) );