// Navigation Functionality

window.mobileNavigation = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {
            
            app.createMobileNavigation();
            app.bindEvents();
        }
    }

    app.cache = () => {
        app.$c = {
            mobileHamburger: document.querySelectorAll( '.site-header__hamburger' ),
            mobileNavigation: document.getElementById( 'mobileNavigation' ),
            primaryNavigation: document.getElementById( 'menu-primary-navigation' ),
            primaryNavigationSubmenu: document.querySelectorAll( '#menu-primary-navigation .sub-menu' ),
            secondaryNavigation: document.getElementById( 'menu-header-menu' ),
        }
    }

    app.hasSection = () => app.$c.primaryNavigation;

    app.bindEvents = () => {

        app.$c.mobileHamburger[0].addEventListener( 'click', app.openMobileNavigation );
        $( document.querySelectorAll( '#mobileNavigation li.menu-item-has-children > a' ) ).on( 'click', app.openMobileDropdown );
        $( document.querySelectorAll( '#mobileNavigation .mobile-navigation-panel-back .mobile-navigation-panel-back-button' ) ).on( 'click', app.mobileMenuBack );
    }

    app.openMobileNavigation = (e) => {

        const siteHeader = document.querySelector( '.site-header' );
        const currentDropdown = document.querySelector( '.mobile-navigation-panel-current' );
        const firstDropdown = document.getElementById( 'panel1' );

        siteHeader.classList.toggle( 'site-header--mobile-menu' );
        app.$c.mobileNavigation.classList.toggle( 'mobile-navigation-container-open' );
        e.currentTarget.classList.toggle( 'open' );
        app.$c.mobileNavigation.classList.remove( 'mobile-navigation-container-secondary' );
        currentDropdown.classList.remove( 'mobile-navigation-panel-current' );
        firstDropdown.classList.add( 'mobile-navigation-panel-current' );
    };

    app.openMobileDropdown = (e) => {

        let currentParent = e.currentTarget.parentNode;
        let currentParentId = currentParent.getAttribute( 'id' );
        let currentDropdown = document.querySelector( '.mobile-navigation-panel-current' );
        let nextDropdown = document.querySelector( '#mobileNavigation .mobile-navigation-panel[data-parent=' + currentParentId + ']' );

        app.$c.mobileNavigation.classList.add( 'mobile-navigation-container-secondary' );
        currentDropdown.classList.remove( 'mobile-navigation-panel-current' );
        nextDropdown.classList.add( 'mobile-navigation-panel-current' );

        e.preventDefault();
    }

    app.mobileMenuBack = e => {

        let currentParentPanel = e.currentTarget.parentNode.parentNode;
        let parentMenuId = currentParentPanel.getAttribute( 'data-parent' );
        let parentMenu = document.querySelector( '.mobile-navigation-primary #' + parentMenuId );
        let parentPanel = parentMenu.parentNode.parentNode;

        app.$c.mobileNavigation.classList.remove( 'mobile-navigation-container-secondary' );
        currentParentPanel.classList.remove( 'mobile-navigation-panel-current' );
        parentPanel.classList.add( 'mobile-navigation-panel-current' );
    }

    app.createMobileNavigation = () => {
        let index = 2;
        let primaryNavigation = document.createElement( 'div' );
        let secondaryNavigation = document.createElement( 'div' );
        primaryNavigation.innerHTML = '<div id="panel1" class="mobile-navigation-panel mobile-navigation-panel-current mobile-navigation-panel-primary">' + app.$c.primaryNavigation.outerHTML + '</div>';
        secondaryNavigation.innerHTML = app.$c.secondaryNavigation.outerHTML;

        $( app.$c.primaryNavigationSubmenu ).each( (i, el) => {

            let backToParent = document.createElement( 'div' );
            let parent = $(el).parent();
            let parentLink = parent.find( '> a' )[0].outerHTML;
            let parentId = parent.attr( 'id' );

            backToParent.innerHTML = '<div class="mobile-navigation-panel-back"><span class="mobile-navigation-panel-back-button"></span>' + parentLink + '</div>';
            primaryNavigation.innerHTML += '<div data-parent="mobile-' + parentId + '" id="panel' + index + '" class="mobile-navigation-panel mobile-navigation-panel-submenu">' + backToParent.innerHTML + $( el )[0].outerHTML + '</div>';
            
            index++;
        });

        $( primaryNavigation.querySelectorAll( '#menu-primary-navigation .sub-menu' ) ).each( (i, el) => $(el).remove() );
        primaryNavigation.querySelector( '#menu-primary-navigation' ).removeAttribute('id');
        $( primaryNavigation.querySelectorAll( '.mobile-navigation-panel-submenu .sub-menu' ) ).each( (i, el) => $(el).removeAttr( 'style' ) );
        $( primaryNavigation.querySelectorAll( '.mobile-navigation-panel ul li' ) ).each( (i, el) => {
            
            let currentId = $(el).attr( 'id' );

            $(el).attr( 'id', 'mobile-' + currentId );
        });
        secondaryNavigation.querySelector( '#menu-header-menu' ).removeAttribute('id');
        $( secondaryNavigation.querySelectorAll( '.menu li' ) ).each( (i, el) => {
            
            let currentId = $(el).attr( 'id' );

            $(el).attr( 'id', 'mobile-secondary-' + currentId );
        });

        const primaryMenu = '<div class="mobile-navigation-primary">' + primaryNavigation.innerHTML + '</div>';
        const secondaryMenu = '<div class="mobile-navigation-secondary">' + secondaryNavigation.innerHTML + '</div>';

        app.$c.mobileNavigation.innerHTML = primaryMenu + secondaryMenu;
    }

    app.init();

} ( window, jQuery, window.mobileNavigation ) );