// Font Awesome Stylesheet Imports
import '../stylesheets/vendor/fontawesome/scss/fontawesome.scss';
import '../stylesheets/vendor/fontawesome/scss/brands.scss';
import '../stylesheets/vendor/fontawesome/scss/solid.scss';
// Custom Styles
import '../stylesheets/main.scss';
// Custom JS
import './includes/tower.navigation';
import './includes/tower.mobileNavigation';
import './includes/tower.iconScroll';
import './includes/tower.controlCenter';
import './includes/tower.homepageAnimation';
import './includes/tower.industryCarousel';
import './includes/tower.industryGallery';
import './includes/tower.sideBySideGallery';
import './includes/tower.faq';
import './includes/tower.technologiesSlider';
import './includes/tower.technologiesCarousel';

jQuery(document).ready(function($) {

	var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;

    $('html').removeClass('no-js');
    
    $( '.page-team__member .page-team__member-name' ).matchHeight();
    $( '.page-team__member .page-team__member-title' ).matchHeight();
    $( '.industries__industries .industries__industry--content-container' ).matchHeight();
    $( '.industry-cta__content-container .industry-cta__header' ).matchHeight();
    $( '.industry-cta__content-container .industry-cta__content' ).matchHeight();
    $( '.blog-content .blog-entry-link' ).matchHeight();
    $( '.single-post .related-articles__link' ).matchHeight();
    $( '.careers .careers__link' ).matchHeight();
    
    // Gravity Forms Modifications
    var gravityFormSelects = $( '.gform_wrapper select' ).select2({
        // 'minimumResultsForSearch': Infinity
        selectOnClose: true
    });

    $( document ).on( 'focus', '.ginput_container_select .select2.select2-container', function() {

        var parentContainer = $(this).parent();

        $( parentContainer ).find( 'select' ).select2( 'open' );
    });

    $( '.blog-container select' ).select2({
        'minimumResultsForSearch': Infinity
    });

    $( '.mobile-categories__select' ).on( 'change', function() {

        var selectVal = $( this ).val();
        window.location.href = selectVal;
    });

    $( '.gform_wrapper input, .gform_wrapper textarea' ).on( 'focus', function() {

        var parentField = $( this ).parents( '.gfield' );
        parentField.addClass( 'remove-label' );
    });

    $( '.gform_wrapper input, .gform_wrapper textarea' ).on( 'blur', function() {

        var inputValue = $( this ).val();
        var parentField = $( this ).parents( '.gfield' );

        if( inputValue === '' ) {
            parentField.removeClass( 'remove-label' );
        }
    });

    $(document).on('gform_post_conditional_logic', (e, formId, fields, isInit) => {

        if( !fields ) return;

        fields.forEach(element => {

            if( $( '#field_' + formId + '_' + element + '.state-dropdown' ).length ) {
    
                if( gf_check_field_rule( formId, element ) === 'show' ) {
    
                    $( '#field_' + formId + '_' + element ).css( 'visibility', 'visible' );
                    $( '#input_' + formId + '_' + element ).attr( 'disabled', false );
                    $( '#gform_submit_button_' + formId ).css( 'display', 'inline-block' );
                    $( '#gform_submit_button_' + formId ).attr( 'disabled', false );
                } else {
                    
                    $( '#field_' + formId + '_' + element ).css( 'visibility', 'hidden' );
                    $( '#input_' + formId + '_' + element ).attr( 'disabled', true );
                    $( '#gform_submit_button_' + formId ).css( 'display', 'none' );
                    $( '#gform_submit_button_' + formId ).attr( 'disabled', true );
                }
            }
        });

    });
		
		// TECHNOLOGIES TEMPLATES
		
		// block hover (ew i'm so sorry @_@)
		if(!isMobile) {
			$('.sidebyside-content--blocks .block').on( "mouseenter", function () {
				if(!$(this).parent().hasClass('open')) {
					$(this).addClass('hovered');
					$(this).siblings().addClass('sibling-hovered');
					$(this).find('.block-title').slideUp(500);
					$(this).find('.block-content-inner').delay(750).fadeIn('fast');
					$(this).addClass('inner-visible');
					$(this).parent().addClass('open');
				}
			});
			
			$('.sidebyside-content--blocks .block').on( "click", function () {
				$(this).removeClass('sibling-hovered');
				$(this).siblings().removeClass('hovered');
				$(this).siblings().find('.block-title').slideDown(500);
				$(this).siblings().find('.block-content-inner').delay(0).fadeOut('fast');
			
				$(this).addClass('hovered');
				$(this).siblings().addClass('sibling-hovered');
				$(this).find('.block-title').slideUp(500);
				$(this).find('.block-content-inner').delay(750).fadeIn('fast');
				$(this).addClass('inner-visible');
			});

			$('.sidebyside-content--blocks').on( "mouseleave", function () {
				var block = $('.sidebyside-content--blocks .block');
				$(block).addClass('animating');
				$(block).removeClass('hovered');
				$(block).siblings().removeClass('sibling-hovered');
				$(block).find('.block-title').slideDown(500);
				$(block).find('.block-content-inner').delay(0).fadeOut('fast');
				$(block).removeClass('inner-visible');
				$(this).removeClass('open');
			});
		} else {
			$('.sidebyside-content--blocks .block').on( "mouseenter", function () {
				$(this).addClass('hovered');
				$(this).siblings().addClass('sibling-hovered');
				$(this).find('.block-title').slideUp(500);
				$(this).find('.block-content-inner').delay(750).fadeIn('fast');
				$(this).addClass('inner-visible');
			});

			$('.sidebyside-content--blocks .block').on( "mouseleave", function () {
				$(this).addClass('animating');
				$(this).siblings().addClass('animating');
				$(this).removeClass('hovered');
				$(this).siblings().removeClass('sibling-hovered');
				$(this).find('.block-title').slideDown(500);
				$(this).find('.block-content-inner').delay(0).fadeOut('fast');
				$(this).removeClass('inner-visible');
			});
		}
		
		// fade scroll blue sections in phases
		$('.fade-scroll-section').each(function () {
			if(!isMobile) { // basically prevent the fancy scrolling on mobile because looks kinda poopy and kinda bad UX (nobody wants to swipe for forever on mobile c'mon the apple site does it and it's awful) */
				var parent_element = $(this);
				var parent_element_id = $(this).attr('id');
				var stage = $('#' + parent_element_id + ' .stage');
				// initialize controller
				var controller = new ScrollMagic.Controller();
				//controller.scrollTo(500);
				var element_count = $('#' + parent_element_id + ' .wrap').length;
				var duration = element_count * 10;
				// build timeline with animation tweens
				var scrollAnimation = new TimelineMax();
				scrollAnimation.set(stage, {autoAlpha: 1});
				$('#' + parent_element_id + ' .wrap').each(function () {
					var id = $(this).attr('id');
					var html_id = '#' + id;
					if($(this).is(':last-child')) {
						scrollAnimation
							.to(html_id, 10, {autoAlpha: 1, ease:Power0.easeNone, x: 0, y: '-50%'})
					} else {
						scrollAnimation
							.to(html_id, 10, {autoAlpha: 1, ease:Power0.easeNone, x: 0, y: '-50%'})
							.to(html_id, 10, {autoAlpha: 0, ease:Power0.easeNone, x: 0, y: '-50%'}, '+=' + duration)
							.to($(html_id).next(), 10, {autoAlpha: 1, ease:Power0.easeNone, x: 0, y: '-50%'});
					}
				});
				// build scene and link scrolling to animation
				var scene = new ScrollMagic.Scene({triggerElement: '#'+parent_element_id, duration: '500%'})
				.setPin('#' + parent_element_id + ' .stage')
				.addTo(controller)
				.setTween(scrollAnimation);
				scene.offset(400);
				
			} else {
				$(this).addClass('using-device');
			}
	});
		
	// slide scroll sections (steps) in phases
	$('.step-scroll-section').each(function () {
		if(!isMobile) {
		var parent_element = $(this);
		var parent_element_id = parent_element['context']['id'];
		var stage = $('#' + parent_element_id + ' .stage');

		// initialize controller
		var controller = new ScrollMagic.Controller();
		var element_count = $('#' + parent_element_id + ' .scroll-image').length;
		var duration = element_count * 1000;

		// build timeline with animation tweens
		var scrollAnimation = new TimelineMax();
		scrollAnimation.set(stage, {autoAlpha: 1});
		$('#' + parent_element_id + ' .scroll-image').each(function () {
			var id = $(this)['context']['id'];
			var html_id = '#' + id;
				scrollAnimation
					.to($(html_id).next(), 10, {autoAlpha: 1, ease:Power0.easeNone, x: 0, y: 0});
		});

		// build scene and link scrolling to animation
		var scene = new ScrollMagic.Scene({triggerElement: '#'+parent_element_id, duration: $(window).height() / 2  + '%'})
		.setPin('#' + parent_element_id + ' .stage')
		.addTo(controller)
		.setTween(scrollAnimation);
		
		scene.offset($(window).height() / 2);
		
		} else {
			$(this).addClass('using-device');
		}
				
	});
	
		var scroll_image_in_view = new IntersectionObserver(function(entries, observer) {
			entries.forEach( function(entry) {
				var yoffset = window.pageYOffset;
				if(entry.isIntersecting && entry.intersectionRatio > 0) {
					//console.log(entry.target.getAttribute('id') + " has intersected");
					var step_number = $('#' + entry.target.getAttribute('id')).attr('data-count');
					var step_counter = $('#' + entry.target.getAttribute('id')).attr('data-counter-id');
					$('#' + step_counter).find('.counter').text(step_number);
				}
			});
		}, {
			threshold: [0.5, 1]
		});
		var scroll_image = document.querySelectorAll('.scroll-image');
		scroll_image.forEach( function(el) {
			scroll_image_in_view.observe(el);
		});
	
	// callout parallax
	if(!isMobile) { // basically prevent the parallax on mobile because looks kinda poopy and kinda bad UX?
		$('.rellax').each(function () {
			$(this).addClass('rellax-in-use');
		});
		var rellax = new Rellax('.rellax', {
			center: true,
		});
	}
	
	// stick phase number throughout sections
	$('.phase-count').each(function() {
		var counter = $(this).attr('id');
		var sticknumber = new StickySidebar('#' + counter, {
			topSpacing: '350vh'
		});
	});
	
	// stick step number throughout sections
	$('.step-scroll-section-counter').each(function() {
		var counter = $(this).attr('id');
		var sticknumber = new StickySidebar('#' + counter, {
			topSpacing: 32
		});
	});
	
	// step number counter in view
	
	// gray sections in view
	var gray_options = {
    root: null,
		rootMargin: "100px 0px 100px 0px",
		threshold: .25
	};
	var gray_in_view = new IntersectionObserver(function(entries, observer) {
		entries.forEach( function(entry) {
			if(entry.isIntersecting) {
				jQuery(entry.target).addClass('in-viewport');
			} else {
				jQuery(entry.target).removeClass('in-viewport');
			}
			if(jQuery(entry.target).parent().find('.gray.in-viewport').length) {
				jQuery(entry.target).parent().addClass('number-over-gray');
			} else {
				jQuery(entry.target).parent().removeClass('number-over-gray');
			}
		});
	}, gray_options);
	var gray_boxes = document.querySelectorAll('.gray');
	gray_boxes.forEach( function(el) {
		gray_in_view.observe(el);
	});
	
	$('.phase__pagination').onePageNav({
		currentClass: 'phase-in-view',
		scrollSpeed: 0,
	});
	
	// phases in view
	/*var phases_in_view = new IntersectionObserver(function(entries, observer) {
		entries.forEach( function(entry) {
			if(entry.isIntersecting) {
				jQuery('a.phase-in-view').removeClass('phase-in-view');
				jQuery('a[href="#'+entry.target.getAttribute('id')+'"]').addClass('phase-in-view');
			} else {
				jQuery('a[href="#'+entry.target.getAttribute('id')+'"]').removeClass('phase-in-view');
			}
		});
	}, {
    root: null,
		rootMargin: "0px 0px 0px 0px",
		threshold:[0, 0.25, 0.50, 0.75, 1]
	});
	var phase_boxes = document.querySelectorAll('.side-by-side__text-heavy');
	phase_boxes.forEach( function(el) {
		phases_in_view.observe(el);
	});*/
	
	// anchor linking
	$('.phase__pagination a').click(function (e) {
		e.preventDefault();
		var hash = $(this).attr('href');
		
		if($(this).hasClass('custom-quote-anchor')) {
			$('html, body').animate({
				scrollTop: $(hash).offset().top + 0
			}, 500);
		} else {
			if(!isMobile) {
				$('html, body').animate({
					scrollTop: $(hash).offset().top + 750
				}, 500);
			} else {
				$('html, body').animate({
					scrollTop: $(hash).offset().top + 0
				}, 500);
			}
		}
	});
	
	if(isMobile) {
		$('.nav-primary .menu>li.menu-item-has-children>a').one( "click", function(e) {
			e.preventDefault();
		});
	}
	
});