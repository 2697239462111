// Navigation Functionality

window.navigation = {};

( function( window, $, app ) {

    app.init = () => {
        app.cache();

        if( app.hasSection() ) {

            app.hideNavigation();
            app.bindEvents();
        }
    }

    app.cache = () => {
        app.$c = {
            primaryNavigation: document.getElementsByClassName( 'nav-primary' ),
            primaryNavigationMenus: document.querySelectorAll( '.nav-primary .sub-menu' ),
            primaryNavigationLink: document.querySelectorAll( '#menu-primary-navigation > .menu-item-has-children > a' ),
        }
    }

    app.hasSection = () => {

        return app.$c.primaryNavigation.length;
    }

    app.bindEvents = () => {

        $( '#menu-primary-navigation > li.menu-item-has-children' ).hoverIntent( app.showSubmenu, app.hideSubmenu );
    }

    app.hideNavigation = () => {

        $( app.$c.primaryNavigationMenus ).each( ( i, el ) => {

            let submenuHeight = $( el ).outerHeight();

            $( el ).attr( 'data-height', submenuHeight );
            $( el ).css( {
                'height': 0,
                'visibility': 'visible'
            })
        });
    }

    app.showSubmenu = (e) => {

        const currentSubmenu = e.currentTarget.querySelector( '.sub-menu' );
        const submenuHeight = currentSubmenu.dataset.height;

        currentSubmenu.style.height = submenuHeight + 'px';
    }

    app.hideSubmenu = (e) => {

        const currentSubmenu = e.currentTarget.querySelector( '.sub-menu' );

        currentSubmenu.style.height = 0;
    }

    app.init();

} ( window, jQuery, window.navigation ) );