// Homepage Animation Functionality

window.homepageAnimation = {};

( function( window, $, app ) {

    app.init = () => {
        // app.cache();

        if( app.isHomepage() ) {

            app.bindEvents();
        }
    }

    app.isHomepage = () => {

        return $( 'body.home' ).length;
    }

    app.bindEvents = () => {

        window.addEventListener( 'load', app.doEntryAnimation );
        window.addEventListener( 'scroll', app.doEntryAnimation );
        window.addEventListener( 'load', app.doProcessAnimation );
        window.addEventListener( 'scroll', app.doProcessAnimation );
    }

    app.doEntryAnimation = () => {

        let timeout = 750;
        let entrySection = document.querySelector( '.home .entry' );
        let entrySectionHeight = entrySection.clientHeight;
        let windowHeight = window.innerHeight;
        let entrySectionTop = Math.round( entrySection.getBoundingClientRect().top );
        let positionToBottom = entrySectionTop - windowHeight;

        if( ( positionToBottom + ( entrySectionHeight / 2 ) ) <= 0 ) {
            
            entrySection.querySelector( '.entry-content-container' ).setAttribute( 'style', 'transform: translateY(0); opacity: 1;' );
            window.removeEventListener( 'load', app.doEntryAnimation );
            window.removeEventListener( 'scroll', app.doEntryAnimation );

            setTimeout( () => entrySection.querySelector( '.entry-image-container' ).setAttribute( 'style', 'transform: translateY(0); opacity: 1;' ), timeout );
        }
    }

    app.doProcessAnimation = () => {

        let timeout = 500;
        let timeoutIterator = timeout;
        let processSection = document.querySelector( '.homepage-process__processes' );
        let processSectionHeight = processSection.clientHeight;
        let windowHeight = window.innerHeight;
        let processSectionTop = Math.round( processSection.getBoundingClientRect().top );
        let positionToBottom = processSectionTop - windowHeight;

        if( ( positionToBottom + processSectionHeight ) <= 0 ) {

            $( $( '.homepage-process__processes .homepage-process__process' ).get().reverse() ).each( ( i, el ) => {

                let currentEl = $( el );

                setTimeout( () => {

                    currentEl.addClass( 'homepage-process__process--show' );
                }, timeout );

                timeout += timeoutIterator;
            });

            window.removeEventListener( 'load', app.doProcessAnimation );
            window.removeEventListener( 'scroll', app.doProcessAnimation );
        }
    }

    app.init();

} ( window, jQuery, window.homepageAnimation ) );